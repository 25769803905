export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_MINDFLOW: Symbol("data/mindflow"),
    SET_CURRENT_STORY: Symbol("data/current")
});

const initialState = {
  client: null,
  mindflow: null,
  currentStory: null,
};

const data = (state = initialState, action) => {
  
    switch (action.type) {
      case ACTIONS.SET_CLIENT: {
        return {
          ...state,
          client: action.client
        };
      }
      case ACTIONS.SET_MINDFLOW: {
        return {
          ...state,
          mindflow: action.mindflow
        }
      }
      case ACTIONS.SET_CURRENT_STORY: {
        return {
          ...state,
          currentStory: action.currentStory
        }
      }
      default: {
        return {
          ...state
        };
      }
    } 
  };
  

export default data;