import React from "react"
import {Link} from "react-router-dom"
import {ButtonOutline, DoubleContainer, blue, Button, purple, grey, FullBack, Grid, Row, Col, FullColRow, Title, HalfRoundedContainer, InlineText} from '../theme'
import {Google} from '../theme/icons'
import Logo from '../components/Logo'

const Login = ()=>(
    <React.Fragment>
        <FullBack color={purple} />
        <br/><br/>
        <Row center="xs">
            <Col xs={8}>
                <Logo/>
            </Col>
        </Row>
        <br/><br/><br/>
        <FullColRow>
            <HalfRoundedContainer>
                <DoubleContainer>
                    <Title color={grey} align="center">
                        Welcome back! <br/>
                        Let’s log you in
                    </Title>
                    <br/><br/>
                    <ButtonOutline color={grey}>
                        <InlineText>
                            Email
                        </InlineText>
                    </ButtonOutline>
                    <br/><br/>
                    <ButtonOutline color={grey}>
                        <InlineText>
                            Password
                        </InlineText>
                    </ButtonOutline>
                    <br/><br/>
                    <Link to="/home">
                        <Button background={grey}>
                            <InlineText>
                                Log in
                            </InlineText>
                        </Button>
                    </Link>
                    <br/><br/>
                    <Link to="/home">
                        <Button background={blue} color="white">
                            <Google style={{
                                transform: "translate(-10px, 2px) scale(2)",
                                }} />&nbsp;&nbsp;&nbsp;
                            <InlineText>
                                Log in with Google
                            </InlineText>
                        </Button>
                    </Link>
                </DoubleContainer>
                

            </HalfRoundedContainer>
        </FullColRow>
    </React.Fragment>
)

export default Login;