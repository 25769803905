import React, {useState, useEffect, useRef} from 'react'
import { useSelector } from 'react-redux';
// import {useSelector} from "react-redux";
import {ResponsiveImage, FullColRow, purple, Title, Container, InlineText, SubTitle, RoundButtonC, Button, lightgrey, Section} from '../theme';
import {Back, Play, Pause} from '../theme/icons'
import {useDispatch} from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/data"
import BlockContent from '@sanity/block-content-to-react'
import {ECATEGORY} from '../MindFlow'


const Story = ({currentStory})=>{
    const mindflow = useSelector(state=>state.data.mindflow)
    const [content, setContent] = useState(null);
    const dispatch = useDispatch();
    const imageRef = useRef(null);
    const [height, setHeight] = useState(null);
    const getButtonHeight = ()=>(-height/2-30);
    const isPlaying = ()=>{
        return (mindflow&&mindflow.isPlaying())
    }
    const [update, setUpdate] = useState(0);
    const [audioData, setAudioData] = useState({duration: null, currentTime:null, currentEmotion:null, isReady: null, currentTitle: null})

    useEffect(() => {
        if (mindflow && currentStory){
            const ns = mindflow.getStory(currentStory);
            if (!content || (ns && ns._id !== currentStory)){
                setContent(ns);
            } 
        }   

        if (imageRef && imageRef.current && !height){
            setHeight(imageRef.current.clientHeight);
        }

        const interval = setInterval(() => {
            if (mindflow){
                setAudioData({
                    duration: mindflow.duration(), 
                    currentTime: mindflow.currentTime(),
                    currentEmotion: mindflow.currentEmotion,
                    isReady: mindflow.isReady(),
                    currentTitle: mindflow.currentTitle
                })
            }
          }, 100);


          return ()=>clearInterval(interval);
    }, [content, setContent, currentStory, mindflow, height, update]);

    if (!currentStory || !mindflow || !content){
        return null;
    } else {
        return(
            <React.Fragment>
                <FullColRow>
                    <Back style={{fontSize: "60px", color: purple}} onClick={()=>{
                        mindflow.kill();
                        dispatch({type: DATA_ACTIONS.SET_CURRENT_STORY, currentStory: null})
                    }} />
                </FullColRow>
                <FullColRow>
                    <ResponsiveImage src={content.graphic} alt={content.title} ref={imageRef} />
                    {(audioData.isReady && !isPlaying())?
                    <RoundButtonC Icon={isPlaying()?null:Play} height={getButtonHeight()} onClick={()=>{
                        if (isPlaying()){
                            // mindflow.pause()
                        } else {
                            mindflow.play()
                        }
                        
                        setUpdate(update+1);
                    }} /> : null}
                </FullColRow>
                <FullColRow>
                    <Container style={{paddingTop: "0px", paddingLeft: "20px"}}>
                        <Title color={purple}>
                            {content.title}
                        </Title>
                        <InlineText color={purple}>
                            {`${Math.floor(audioData.currentTime)} / ${Math.floor(audioData.duration)}`}
                        </InlineText>

                        <SubTitle color={purple}>Title</SubTitle>
                        <InlineText color={purple}>
                            {audioData.currentTitle}
                        </InlineText>

                        

                        <SubTitle color={purple}>Emotional State</SubTitle>

                        {Object.keys(ECATEGORY).map(e=>{
                            const v = ECATEGORY[e];
                            const eq = (v===audioData.currentEmotion);
                            return <Section key={e}>
                                    <Button color={eq?lightgrey:purple} 
                                            background={eq?purple:lightgrey}
                                            onClick={()=>{
                                                mindflow.setCurrentEmotion(v)
                                            }}
                                            >
                                        {e}
                                    </Button> <br/><br/>
                            </Section>
                        })}



                        <InlineText color={purple}>
                            <BlockContent blocks={content.description} />
                        </InlineText>
                        
                    </Container>
                </FullColRow>
            </React.Fragment>
        )
    }
}

export default Story;
