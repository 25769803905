import React from "react";
import {Section, Row, Col, xlightgrey, purple, InlineText} from '../theme';
import {Explore, Home, Profile} from '../theme/icons';
import {Link} from "react-router-dom"
import {useDispatch} from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/data"

const FooterIconContainer = ({Icon, text, scale, go, action})=>(
    <Col xs={4}>
        <Link to={go} onClick={action?action:null}>
            <Section style={{
                textAlign: "center",
                fontSize: "30px",
                color: purple,
                lineHeight: "4px"
            }}>
                <Icon style={{transform: `scale(${scale || 1})`}} />
                <br/>
                <InlineText style={{fontSize: "11px"}}>
                    {text}
                </InlineText>
            </Section>
        </Link>
    </Col>
)

const Footer = ()=>{
    const dispatch = useDispatch();

    return (
        <Section style={{
            background: xlightgrey,
            borderTop: "3px solid " + purple,
            paddingTop: "8px",
            width: "100%",
            position: "fixed",
            left: "0px",
            bottom: "0px",
            minHeight: "51px"
        }}>
                <Row>
                    <Col xs={12} sm={6} smOffset={3} lg={4} lgOffset={4}>
                        <Row>
                            <FooterIconContainer Icon={Explore}     text="Explore"  go="/overview" /> 
                            <FooterIconContainer Icon={Home}        text="Home"     go="/home" action={()=>{
                                dispatch({type: DATA_ACTIONS.SET_CURRENT_STORY, currentStory: null})
                            }} /> 
                            <FooterIconContainer Icon={Profile}     text="Profile"  go="/" scale={0.83} /> 
                        </Row>
                    </Col>
                </Row>
            
        </Section>
);}

export default Footer;