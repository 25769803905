import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import Footer from '../components/Footer';
import Story from '../components/Story';
import {FullBack, Section, purple, Row, Col, turner, RoundButton, RoundedContainer, Title, InlineText, ShadowStyle, lightgrey, Container, FullColRow} from '../theme'
import {Play} from '../theme/icons';
import BlockContent from '@sanity/block-content-to-react'
import { ACTIONS as DATA_ACTIONS } from "../reducers/data"

const NarrativeCard = ({graphic, title, description, _id})=>{
    const dispatch = useDispatch();

    const onClick = ()=>{
        dispatch({type: DATA_ACTIONS.SET_CURRENT_STORY, currentStory: _id})
    }

    return (
        <RoundedContainer style={{ 
            margin: "20px",
            padding: "0px",
            marginBottom: "30px",
            ...ShadowStyle}} background="white">
                <div style={{
                    width: "100%",
                    height: "200px",
                    backgroundImage: `url(${graphic})`,
                    backgroundSize: "100% auto",
                    backgroundRepeat: "no-repeat",
                    borderRadius: `${turner} ${turner} 0px 0px `,
                    overflow:"hidden"
                    }} onClick={onClick}>
                </div>
                <Row>
                    <Col xs={2} xsOffset={10}>
                        <RoundButton Icon={Play} right="0px" onClick={onClick} />
                    </Col>
                </Row>
                <Container >
                    <Title style={{paddingLeft: "10px"}} color={purple}>{title}</Title>
                    <Section style={{paddingLeft: "10px"}}>
                        <InlineText color={purple}>
                            <BlockContent blocks={description} />
                        </InlineText>
                    </Section>
                </Container>       
        </RoundedContainer>
    )
    
}

const Home = ()=>{
    const mindflow = useSelector(state => state.data.mindflow);
    const currentStory = useSelector(state => state.data.currentStory);

    if (mindflow && mindflow.stories && mindflow.stories.length>0){
        return (
            <React.Fragment>
                   <FullBack color={currentStory?"white":lightgrey} />
                   {currentStory?
                    <Story currentStory={currentStory} /> :
                    <FullColRow>
                        <Container>
                            {mindflow.stories.map((story)=>(
                                <NarrativeCard title={story.title} key={story._id} graphic={story.graphic} description={story.description} _id={story._id}/>
                            ))}
                        </Container>
                    </FullColRow>
                    }
                    <br/><br/><br/><br/>
                    <Footer />    
           </React.Fragment> )
    } else {
        return null;
    }
}; 

export default Home;