export const ECATEGORY = {
    static:     "static",
    tense:      "tense / anxious",
    excited:    "excited / happy",
    calm:       'calm / content',
    bored:      'bored / sad'
};


class AudioEngine {

    constructor(){
        this.sound = {fx: null, music:null, narration:null};
        this.soundLibrary = null;
        const parent = this;
        this.currentKey = null;
        this.lastTime = 0;

        this.s = (_p5)=>{

            _p5.preload = ()=>{
                // _p5.soundFormats('mp3')
                // parent.sound = _p5.loadSound('https://cdn.sanity.io/files/xg90auls/production/d9f44ae78079e45050a92098dcbff7a5c68e6a99.mp3')
            }

            _p5.setup = ()=>{
                _p5.noCanvas();
            }

            _p5.draw = ()=>{
                // if (parent.sound.fx){
                //     console.log(parent.sound.fx.currentTime())
                // }
            }

            _p5.play = ()=>{
                if (parent.sound.fx){
                    for (const s in parent.sound){
                        parent.sound[s].play()
                    }
                }
            }

            // _p5.pause = ()=>{
            //     if (parent.sound.fx){
            //         for (const s in parent.sound){
            //             parent.sound[s].pause()
            //         }
            //     }
            // }

            _p5.loadStory = (data, onEnded)=>{
                parent.soundLibrary = new Map();


                const getLoadedObject = (data)=>{
                    return {
                        fx:         _p5.loadSound(data.fx),
                        music:      _p5.loadSound(data.music),
                        narration:  _p5.loadSound(data.narration),
                    }
                }

                for (let [key, value] of data) {
                    switch (key) {
                        case "intro":
                        case "exit":
                            parent.soundLibrary.set(key, getLoadedObject(value))
                            break;
                        default:
                            parent.soundLibrary.set(key, {
                                LOW:    getLoadedObject(value.LOW),
                                MED:    getLoadedObject(value.MED),
                                HIGH:   getLoadedObject(value.HIGH)
                            })
                            break;
                    }
                }

                parent.sound = getLoadedObject(data.get("intro"));
                parent.currentKey = "intro";
                parent.updateTime();
                parent.sound.fx.onended(()=>{
                    onEnded(parent.currentKey);
                })
            }

            _p5.nextModule = (key, selector)=>{
                if (selector === "MID"){
                    selector = "MED"
                }
                const onended = parent.sound.fx._onended;
                delete parent.sound.fx;
                delete parent.sound.music;
                delete parent.sound.narration;

                let ns = null;

                if (selector){
                    ns = parent.soundLibrary.get(key)[selector];
                } else { //get exit or intro files
                    ns = parent.soundLibrary.get(key);
                }

                if (ns){
                    parent.sound.fx         = ns.fx;
                    parent.sound.music      = ns.music;
                    parent.sound.narration  = ns.narration;

                    parent.sound.fx.onended(onended);

                    for (const s in parent.sound){
                        parent.sound[s].play()
                    }
                    parent.updateTime();
                    parent.currentKey = key;
                } else {
                    alert("Something went wrong while loading new module")
                }
            }
        }

        // eslint-disable-next-line no-undef
        this.a = new p5(this.s)
        this.play = this.a.play;
        this.pause = this.a.pause;
        this.loadStory = this.a.loadStory;
        this.nextModule = this.a.nextModule;
    }

    kill(){
        if (this.sound.fx){
            for (const s in this.sound){
                this.sound[s].stop()
                delete this.sound[s];
            }
        }
        this.sound = {fx: null, music:null, narration:null};
        this.soundLibrary = null;
        this.currentKey = null;
        this.lastTime = 0;
    }

    updateTime(){
        this.lastTime = new Date();
    }

    elapsedTime(){
        return Math.floor((new Date() - this.lastTime)/1000)
    }

    isPlaying(){
        return this.sound.fx && this.sound.fx.isPlaying();
    }
}

class MindFlow {
    constructor(stories){
        this.currentEmotion = ECATEGORY.static;
        this.stories = stories;
        this.audioEngine = new AudioEngine();
        this.currentStory = undefined;
        this.currentTitle = undefined;
    }

    kill(){
        this.currentStory = undefined;
        this.currentTitle = undefined;
        this.audioEngine.kill();
    }

    setCurrentEmotion(newEmotion){
       this.currentEmotion = newEmotion;
    }

    print(){
        console.log(this.stories); 
    }

    getStory(id){
        let rv = null;
        const parent = this;
        if (this.currentStory && this.currentStory._id === id){
            rv = this.currentStory;

        } else {
            this.stories.forEach(story => {
                if (story._id === id){
                    rv = story;
                }
            });

            if (rv){
                this.currentStory = rv;
                console.log(this.currentStory)
                const loadmapper = new Map();
                loadmapper.set("intro", this.currentStory.Intro);
                loadmapper.set("exit", this.currentStory.Exit);
                this.currentStory.modules.forEach(m=>{
                    loadmapper.set(m.data._id, {
                        LOW: m.data.LOW,
                        MED: m.data.MED,
                        HIGH: m.data.HIGH
                    })
                })
                this.currentTitle = "intro";

                const onEnded = (currentKey)=>{

                    if (!currentKey || !this.currentTitle){
                        return;
                    }

                    console.log(`On ENDED KEY :: ${currentKey}`)

                    const playExit = ()=>{
                        //implement me
                        this.currentTitle = "exit";
                        this.audioEngine.nextModule("exit", null);
                    }   

                    if (currentKey === "intro"){
                        const next = parent.getNextModule(rv.initialisation);
                        if (next){
                            this.audioEngine.nextModule(next.module._ref, next.intensity);
                            this.currentTitle = next.title;
                        } else {
                            // alert("something went wrong : no initialisation found")
                            playExit()
                        }
                    } else if (currentKey === "exit"){
                        //do nothing?
                    } else {
                        let currentModule = null;
                        rv.modules.forEach(m=>{
                            if (m.data._id === currentKey){
                                currentModule = m;
                            }
                        })
                        
                        if (currentModule){
                            if (currentModule.next && currentModule.next.length > 0){
                                console.log("looking for next module", currentModule.next)
                                const next = parent.getNextModule(currentModule.next);
                                if (next){
                                    this.audioEngine.nextModule(next.module._ref, next.intensity);
                                    this.currentTitle = next.title;
                                } else {
                                    playExit()
                                }
                            } else {
                                playExit()
                            }

                        } else {
                            alert("Could not find module")
                            playExit()
                        }
                        
                    }
                };

                this.audioEngine.loadStory(loadmapper, onEnded);   
            }   
        }
        return rv;
    }

    getNextModule(nexts){
        let rv = null;
        let backup = null;
        console.log(nexts)
        nexts.forEach(n=>{
            if (this.isCurrentEmotion(n.category)){
                rv = n;
            } else if (n.category.toLowerCase()==="static"){
                backup = n;
            }
        })

        if (rv){
            return rv;
        } else {
            return backup;
        }
    }

    isCurrentEmotion(emotion){
        return this.currentEmotion.indexOf(emotion.toLowerCase()) !== -1;
    }

    play()          { this.audioEngine.play()                       }
    // pause()         { this.audioEngine.pause()}
    isPlaying()     { return this.audioEngine.isPlaying()                  }
    duration(){ 
        if (this.audioEngine.sound && this.audioEngine.sound.fx){
            return this.audioEngine.sound.fx.duration()      
        } else {
            return 0;
        }
    }
    currentTime(){ 
        if (this.isPlaying()){
            return this.audioEngine.elapsedTime()
        } else {
            return 0;
        }
    }
    isReady(){
        return ((this.audioEngine.sound.fx && this.audioEngine.sound.fx.duration() > 0) 
        && (this.audioEngine.sound.music && this.audioEngine.sound.music.duration() > 0)
        && (this.audioEngine.sound.narration && this.audioEngine.sound.narration.duration() > 0))
    }
}

export default MindFlow;