/* TODO: 
      - r̶o̶u̶t̶i̶n̶g̶
      -̶ r̶e̶d̶u̶x̶
      - v̶i̶s̶u̶a̶l̶ c̶o̶m̶p̶o̶n̶e̶n̶t̶s̶
      - c̶l̶i̶e̶n̶t̶
      -̶ i̶f̶ s̶c̶r̶e̶e̶n̶ i̶s̶ t̶o̶o̶ l̶a̶r̶g̶e̶,̶ m̶a̶k̶e̶ i̶t̶ m̶o̶b̶i̶l̶e̶
      -̶ r̶e̶m̶o̶v̶e̶ d̶u̶r̶a̶t̶i̶o̶n̶,̶ r̶e̶p̶l̶a̶c̶e̶ w̶i̶t̶h̶ m̶o̶d̶u̶l̶e̶s̶
      // - implement p5
      - implement audio logic
        /̶/̶ -̶ l̶e̶t̶ i̶n̶t̶r̶o̶ a̶u̶d̶i̶o̶ p̶l̶a̶y̶
        -̶ i̶n̶t̶r̶o̶d̶u̶c̶e̶ f̶i̶r̶s̶t̶ m̶a̶p̶p̶i̶n̶g̶ f̶r̶o̶m̶ i̶n̶t̶r̶o̶ i̶n̶ t̶h̶e̶ C̶M̶S̶
        -̶ b̶r̶i̶n̶g̶ i̶n̶ b̶e̶g̶i̶n̶n̶i̶n̶g̶
      - d̶i̶s̶p̶l̶a̶y̶ n̶a̶m̶e̶ o̶f̶ m̶o̶d̶u̶l̶e̶
      - make audio stop when going back
      - (display name of next module)
      - move to app
*/

import React, {useEffect} from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "./reducers/data"
import sanityclient from './client';
import Mindflow from './MindFlow'
import Store from "./store";
import Home from './pages/Home'
import Overview from './pages/Overview'
import Theme from './theme'
import Login from './pages/Login'
import history from './history'

const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    }

    if (data.client && !data.mindflow){
        data.client.fetchNarratives()
        .then((data)=>{
          dispatch({type: DATA_ACTIONS.SET_MINDFLOW, mindflow: new Mindflow(data)});
        })
    }
  })
  return (
    null
  )
}

const App = ()=>{
  return (
    <Provider store={Store}>
      <DataHelper />
      <Router history={history}>
        <Theme>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/overview" component={Overview} />
            <Route exact path="/home" component={Home} />
            <Route component={Login}/>
          </Switch>
          </Theme>
      </Router>
    </Provider>
  );
}

export default App;
