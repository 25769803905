import React from 'react';
import {Grid, Row, Col, Container, InlineText, Text, FullColRow, HalfCol, ColContainer, grey, purple, lightgrey} from '../theme'
import {Title, HalfRoundedContainer, Button, ButtonOutline, ShadowStyle, InvertedHalfRoundedContainer, SmallButton} from '../theme'
import {Eye, Play, Back, Time, Explore, Saved, Profile, Google} from '../theme/icons'
import Logo from '../components/Logo'
import Toggle from '../components/Toggle'

const Overview = ()=>(
    <Grid>
        <FullColRow>
            <Container>
                <Button>
                    allo
                </Button>
            </Container>
        </FullColRow>
        <FullColRow>
            <Container>
                <ButtonOutline>
                    allo
                </ButtonOutline>
            </Container>
        </FullColRow>
        <Row>
            <Col xs={3}>
                <SmallButton>
                    allo
                </SmallButton>
            </Col>
            <Col xs={3}>
                <Toggle onChange={(val)=>{console.log(val);}} initial={false} />
            </Col>
        </Row>
        <FullColRow>
            <Container style={{backgroundColor: "green"}}>
                <InlineText>
                    I am full width in a container
                </InlineText>
            </Container>
        </FullColRow>
        <Row>
            <HalfCol>
                <Container style={{backgroundColor: "blue"}}>
                    <Text>
                        I am half width in a container
                    </Text>
                </Container>
            </HalfCol>
            <ColContainer xs={6} style={{backgroundColor: "pink"}}>
                <InlineText>
                    I am half width in a container
                </InlineText>
            </ColContainer>
        </Row>
        <Row>
            <Col xs={1} style={{backgroundColor:grey}}></Col>
            <Col xs={1} style={{backgroundColor:purple}}></Col>
            <Col xs={1} style={{backgroundColor:lightgrey}}></Col>
        </Row>
        <Row>
            <Col xs={1}> <Eye /> </Col>
            <Col xs={1}> <Play /> </Col>
            <Col xs={1}> <Back /> </Col>
            <Col xs={1}> <Time /> </Col>
            <Col xs={1}> <Explore /> </Col>
            <Col xs={1}> <Saved /> </Col>
            <Col xs={1}> <Profile /> </Col>
            <Col xs={1}> <Google /> </Col>
        </Row>
        <FullColRow>
            <Logo />
        </FullColRow>  
        <FullColRow>
            <Container>
                <Title align="center">
                    Welcome back
                </Title>
            </Container>
        </FullColRow>
        <FullColRow>
            <HalfRoundedContainer>
                <Container>
                    <Title align="center" color={grey}>
                        I am in the halfcontainer
                    </Title>
                </Container>
            </HalfRoundedContainer>
        </FullColRow>
        <FullColRow>
            <Container >
                <InvertedHalfRoundedContainer style={ShadowStyle}>
                    <Container>
                        <Title align="center" color={grey}>
                            I am in the halfcontainer
                        </Title>
                    </Container>
                </InvertedHalfRoundedContainer>
            </Container>
        </FullColRow>
        

    </Grid>
); 

export default Overview;