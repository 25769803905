import React from 'react';
// eslint-disable-next-line
import graphic from '../assets/graphics/Logo.svg';
// eslint-disable-next-line
import {ResponsiveImage, Container} from '../theme'

const Logo = ()=>(<Container>
    <ResponsiveImage src={graphic} alt="Logo"/>
</Container>)

export default Logo;