
import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import "@vetixy/circular-std";
import {Col, Row, Grid} from 'react-styled-flexboxgrid';
import phone from '../assets/graphics/phone.png'

// CONSTANTS
const margin = "0.7em";
const doubleMargin = "3em";
const buttonMargin = "0.8em";
const fontSizeRegular = "16px";
const fontSizeButton = "17px";
const fontSizeTitle = "31px";
const fontSizeSubtitle = "22px";
export const turner = "25px";
const buttonTurner = "13px";
const roundButton = "60";
const roundButtonSize = roundButton+"px";

export const grey = "#B7B7B7";
export const lightgrey = "#E6E6E6"
export const xlightgrey = "#F9F9F9"
export const purple = "#6572A9";
export const blue = "#4285F4";


// TYPO
export const InlineText = styled.span`
  color: ${props => props.color ||  "inherit"};
  font-size: ${fontSizeRegular};
  text-align: ${props => props.align ||  "left"};
`

export const Text = styled(InlineText)`
  padding: ${margin};
  text-align: ${props => props.align ||  "left"};
`;

export const Title = styled.h1`
    color: ${props => props.color ||  "black"};
    font-size: ${fontSizeTitle};
    text-align: ${props => props.align ||  "left"};
`

export const SubTitle = styled.h2`
    color: ${props => props.color ||  "black"};
    font-size: ${fontSizeSubtitle};
    text-align: ${props => props.align ||  "left"};
`

export const FullBack = styled.div`
  background: ${props => props.color ||  "black"};
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  z-index: -100;
`


// IMAGES
export const ResponsiveImage = styled.img`
  width: 100%;
`

// CONTAINERS
export const Section = styled.div``
export const Container = styled.div`
    padding: ${margin};
`

export const DoubleContainer = styled.div`
    padding: ${doubleMargin};
`

export const HalfRoundedContainer = styled(Container)`
  border-radius: ${turner} ${turner} 0px 0px;
  background: ${props => props.background ||  "black"};
  padding: ${margin};
  `

  export const RoundedContainer = styled(Container)`
    border-radius: ${turner} ${turner} ${turner} ${turner};
    background: ${props => props.background ||  "black"};
    padding: ${margin};
  `

export const ShadowStyle = {"WebkitBoxShadow":"0px 2px 19px -1px rgba(0,0,0,0.75)","MozBoxShadow":"0px 2px 19px -1px rgba(0,0,0,0.75)","boxShadow":"0px 2px 19px -1px rgba(0,0,0,0.75)"}


export const InvertedHalfRoundedContainer = styled(HalfRoundedContainer)`
  border-radius: 0px 0px ${turner} ${turner};
`



export const Button = styled.button`
  border-radius: ${buttonTurner};
  background: ${props => props.background ||  grey};
  color: ${props => props.color ||  "black"};
  padding: ${buttonMargin};
  font-size: ${fontSizeButton};
  width: 100%;
  text-align: ${props => props.align ||  "center"};
`

export const ButtonOutline = styled(Button)`
  background:inherit;
  border: 2px solid;
  border-color: ${props => props.color ||  "black"};
  color: ${props => props.color ||  "black"};
  text-align: ${props => props.align ||  "left"};
`

export const SmallButton = styled(ButtonOutline)`
  padding: 2px;
`

export {Grid, Col, Row} from 'react-styled-flexboxgrid';

export const FullColRow = (props)=>(
    <Row>
        <Col xs={12}>
            {props.children}
        </Col>
    </Row>
)

export const HalfCol = (props)=>
    (<Col xs={6}>
        {props.children}
    </Col>)

export const ColContainer = (props)=>(
    <Col {...props}>
        <Container>
            {props.children}
        </Container>
    </Col>
)

export const RoundButton = ({Icon, right, onClick})=>(
  <Section style={{background: purple, 
    width: roundButtonSize, 
    height: roundButtonSize,
    position: "absolute",
    transform: `translateY(-${roundButton/2}px)`,
    borderRadius: `50%`,
    float: "right"
    }} onClick={onClick}> 
    <Section style={{
        color: "white",
        fontSize: "50px",
        position: "absolute",
        left: "9px",
        top: "4px"
    }}>  <Icon/>
    </Section>
        
    </Section>
)

export const RoundButtonC = ({Icon, height, onClick})=>(
  <Section style={{background: purple, 
    width: roundButtonSize, 
    height: roundButtonSize,
    position: "absolute",
    transform: `translate(-50%, ${height}px)`,
    borderRadius: `50%`,
    left: "50%",
    }} onClick={onClick}> 
    <Section style={{
        color: "white",
        fontSize: "50px",
        position: "absolute",
        left: "9px",
        top: "4px"
    }}>  <Icon/>
    </Section>
        
    </Section>
)

// THEME
const theme = {
    flexboxgrid: {
      // Defaults
      gridSize: 12, // columns
      gutterWidth: 0, // rem
      outerMargin: 0, // rem
      mediaQuery: 'only screen',
      container: {
        sm: 46, // rem
        md: 61, // rem
        lg: 76  // rem
      },
      breakpoints: {
        xs: 0,  // em
        sm: 48, // em
        md: 64, // em
        lg: 75  // em
      }
    }
  }





const Theme = (props)=>{
  const content = <ThemeProvider theme={theme}> 
  <Grid>

  <Row >
    <Col xs={12} sm={6} smOffset={3}>
      {props.children} 
    </Col>
  </Row>
  </Grid>
  </ThemeProvider>
  return content;

  
  }

export default Theme;