import sanityClient from '@sanity/client'
import {settings} from './connector_settings.json'
const prevFetched = {};

export const client = (dispatch)=>{
    let _ = settings;
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: false
    })

    const fetch = (query)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query]){
                console.log("reused cached query");
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const fetchNarratives = ()=>{
        const getTripleFiles = (name)=>`${name}
            {
                "fx": fx.asset->url,
                "music": music.asset->url,
                "narration": narration.asset->url,
              }
        `

        const nextFields = `title, 
        "category" : category->title,
        intensity,
        module`;

        return fetch(`*[_type=="story"]{
            ${getTripleFiles("Intro")},
            "initialisation":initialisation.modules[]{
                ${nextFields}
            },
            ${getTripleFiles("Exit")},
            description, 
            maxmodules,
            "graphic" : graphic.asset->url,
            title, 
            _id,
            modules[]{
                        title,
                        key,
                        'data': module->{
                            title,_id,
                            ${getTripleFiles("HIGH")},
                            ${getTripleFiles("MED")},
                            ${getTripleFiles("LOW")}
                        },
                        next[]{
                            ${nextFields}
                        }
                    }
                }`);
    }

    return {
        fetch, fetchNarratives
    }
}

export const FETCH_STATES = Object.freeze({
    IDLE: Symbol("fetch/idle"),
    BUSY: Symbol("fetch/busy"),
    ERROR: Symbol("fetch/error"),
});

export default client;

